import { Component, OnInit, Input } from "@angular/core"
import { Router } from "@angular/router"
import { AuthService } from "@puntaje/shared/core"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "landing-abily",
    templateUrl: "landing-abily.component.html",
    styleUrls: ["landing-abily.component.scss"]
})
export class LandingAbilyComponent implements OnInit {
    @Input() enableRegister = false

    config: typeof config
    landingImage = "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/shared/base/fondo_base.jpg"
    logo: string
    logoFooter: string
    logosExtra: any[]

    constructor(private router: Router, public authService: AuthService) {}

    ngOnInit() {
        this.config = config
        if (this.authService.isLoggedIn()) {
            this.router.navigate(["home"])
        }

        this.landingImage =
            this.config.app.baseStyle?.landing?.backgroundImage ||
            "https://bucket-puntaje-reloaded.s3-us-west-1.amazonaws.com/shared/base/fondo_base.jpg"

        this.logo = this.config.app.baseStyle?.landing?.logo
            ? this.config.app.assets[this.config.app.baseStyle?.landing?.logo]
            : this.config.app.assets.logoLargeLight

        this.logoFooter = this.config.app.baseStyle?.landing?.logoFooter
            ? this.config.app.assets[this.config.app.baseStyle?.landing?.logoFooter]
            : this.config.app.assets.logoMediumLight

        this.logosExtra = this.config.app.baseStyle?.footer?.logosExtras
            ? this.config.app.baseStyle?.footer?.logosExtras
            : []
    }

    goToRegister() {
        this.router.navigate(["/usuarios/registro"])
    }
}
