<nav class="navbar clearfix">
    <div class="navbar-header">
        <div class="navbar-logo-container">
            <a href="#section1" easyScrollTo [easyScrollToPadding]="0" [easyScrollToDuration]="800"
                [easyScrollToEasing]="'easeInOutExpo'">
                <img class="navbar-logo" src={{logo}} alt="{{ config.plataforma.info.companyName }}" />
            </a>
        </div>
        <div class="toggle-wrap">
            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                <span class="sr-only">Toggle navigation</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
        </div>
    </div>
    <div class="navbar-collapse collapse">
        <ul class="nav navbar-nav">
            <li>
                <a href="#section1" id="pagina-inicial" easyScrollTo [easyScrollToPadding]="0"
                    [easyScrollToDuration]="800" [easyScrollToEasing]="'easeInOutExpo'">
                    Página Inicial
                </a>
            </li>
            <li>
                <a href="#section2" id="que-es" easyScrollTo [easyScrollToPadding]="0" [easyScrollToDuration]="800"
                    [easyScrollToEasing]="'easeInOutExpo'">
                    ¿Qué es Abily?
                </a>
            </li>
            <li>
                <a href="#section3" id="especialidades-tecnicas" easyScrollTo [easyScrollToPadding]="0"
                    [easyScrollToDuration]="800" [easyScrollToEasing]="'easeInOutExpo'">
                    Especialidades técnicas
                </a>
            </li>
            <li>
                <a href="#section4" id="funcionalidades" easyScrollTo [easyScrollToPadding]="0"
                    [easyScrollToDuration]="800" [easyScrollToEasing]="'easeInOutExpo'">
                    Funcionalidades
                </a>
            </li>
            <li>
                <a href="#section5" id="blog" easyScrollTo [easyScrollToPadding]="0" [easyScrollToDuration]="800"
                    [easyScrollToEasing]="'easeInOutExpo'">
                    Blog
                </a>
            </li>
            <li>
                <a href="#section8" id="contacto" easyScrollTo [easyScrollToPadding]="0" [easyScrollToDuration]="800"
                    [easyScrollToEasing]="'easeInOutExpo'">
                    Contacto
                </a>
            </li>
            <li>
                <button id="boton-registro" class="btn btn_style btn-default btn-lg btn-block" (click)="goToRegister()">
                    Regístrate
                </button>
            </li>
        </ul>
    </div>
</nav>