import { Component, OnInit } from "@angular/core"
import { Router } from "@angular/router"
import { AppConfig } from "@puntaje/shared/core"
declare const config: AppConfig

@Component({
    selector: "landing-navbar",
    templateUrl: "landing-navbar.component.html",
    styleUrls: ["landing-navbar.component.scss"]
})
export class LandingNavbarComponent implements OnInit {
    config: typeof config
    logo: string

    constructor(protected router: Router) {}

    ngOnInit(): void {
        this.config = config

        this.logo = this.config.app.baseStyle?.landing?.logo
            ? this.config.app.assets[this.config.app.baseStyle?.landing?.logo]
            : this.config.app.assets.logoLargeLight
    }

    goToRegister() {
        this.router.navigate(["/usuarios/registro"])
    }
}
