<div class="contenedor">
    <!-- Login -->
    <div id="section1">
        <div class="box">
            <div class="fading-image" [lazyLoad]="landingImage" [offset]="200"></div>
            <landing-navbar></landing-navbar>
            <div class="box-inner-margin">
                <div class="landing-logo">
                    <img
                        src="{{ logo }}"
                        alt="{{ config.plataforma.info.companyName }}"
                        title="{{ config.plataforma.info.companyName }}"
                    />
                </div>
                <h1 class="title basic-text-shadow">
                    Formando
                    <span class="highlighted">Especialistas</span>
                </h1>
                <login-standard
                    class="login"
                    [enableContactUs]="false"
                    [enableRegister]="enableRegister"
                    [enableFacebookLogin]="false"
                    [enablePasswordRecovery]="true"
                ></login-standard>
            </div>
        </div>
    </div>
    <!-- Qué es sección -->
    <div id="section2">
        <div class="what-is">
            <h1 class="title">
                ¿Qué es
                <span class="primary">Abily</span>
                ?
            </h1>
            <h3 class="description">
                <strong>Abily</strong>
                es una plataforma educativa de
                <strong>formación flexible y modular</strong>
                desarrollada por
                <strong>Open Green Road.</strong>
                Esta plataforma tiene un enfoque de
                <strong>aprendizaje práctico</strong>
                que permite a los estudiantes de
                <strong>enseñanza media técnico-profesional</strong>
                , llevar a cabo sus procesos educativos de manera simultánea, preparándolos tanto en el área técnica
                como para la prueba de admisión universitaria.
            </h3>
            <div class="btn-container">
                <button class="btn btn-lg basic-shadow main-button" (click)="goToRegister()">
                    Explora los contenidos
                </button>
            </div>
        </div>
        <div class="graph-img">
            <img
                alt="grafico"
                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/abily/landing/grafico_estadisticas.png"
            />
        </div>
    </div>
    <!-- Especialidades técnicas -->
    <div id="section3">
        <div class="box">
            <div class="box-inner-margin">
                <h1 class="title">
                    Contenido desarrollado para
                    <span class="primary">5 especialidades técnicas</span>
                </h1>
                <div class="line"></div>
                <div class="description-container">
                    <h3 class="description">
                        Encuentra desde guías de contenido, cápsulas de aprendizaje, planes de clase, sesiones de
                        práctica y más. Para las especialidades técnicas con mayor demanda
                    </h3>
                </div>
                <div class="specialties-container">
                    <div class="specialties-icon">
                        <img
                            src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/abily/landing/grafico_enfermeria.png"
                            alt="grafico-enfermeria"
                        />
                        <h2>Atención de Enfermería</h2>
                    </div>
                    <div class="specialties-icon specialties-icon-primary">
                        <img
                            src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/abily/landing/grafico_mecanica.png"
                            alt="grafico-mecanica"
                        />
                        <h2>Mecánica Automotriz</h2>
                    </div>
                    <div class="specialties-icon basic-shadow">
                        <img
                            src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/abily/landing/grafico_contabilidad.png"
                            alt="grafico-contabilidad"
                        />
                        <h2>Contabilidad</h2>
                    </div>
                    <div class="specialties-icon specialties-icon-primary">
                        <img
                            src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/abily/landing/grafico_administracion.png"
                            alt="grafico-administracion"
                        />
                        <h2>Administración de empresas</h2>
                    </div>
                    <div class="specialties-icon">
                        <img
                            src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/abily/landing/grafico_gastonomia.png"
                            alt="grafico-gastronomia"
                        />
                        <h2>Gastronomía</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Funcionalidades -->
    <div id="section4">
        <div class="box">
            <div class="box-inner-margin">
                <h1 class="title basic-text-shadow">
                    Aprendizaje basado en la
                    <span class="highlighted">práctica</span>
                </h1>
                <div class="skills-container">
                    <div class="skill-divider"></div>
                    <div class="skill">
                        <div class="icon-container">
                            <img
                                alt="aptitudes-icono"
                                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/abily/landing/icono1_aprendizaje_practico.png"
                            />
                        </div>
                        <h3 class="basic-text-shadow">Aptitudes</h3>
                    </div>
                    <div class="skill">
                        <div class="icon-container">
                            <img
                                alt="competencias-icono"
                                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/abily/landing/icono2_aprendizaje_practico.png"
                            />
                        </div>
                        <h3 class="basic-text-shadow">Competencias</h3>
                    </div>
                    <div class="skill">
                        <div class="icon-container">
                            <img
                                alt="habilidades-icono"
                                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/abily/landing/icono3_aprendizaje_practico.png"
                            />
                        </div>
                        <h3 class="basic-text-shadow">Habilidades</h3>
                    </div>
                    <div class="skill">
                        <div class="icon-container">
                            <img
                                alt="conocimientos-icono"
                                src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/abily/landing/icono4_aprendizaje_practico.png"
                            />
                        </div>
                        <h3 class="basic-text-shadow">Conocimientos</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Blog -->
    <div id="section5">
        <div class="box box-inner-margin">
            <h1 class="title">Blog</h1>
            <div class="inner-wrap">
                <blog-landing></blog-landing>
            </div>
        </div>
    </div>
    <!-- Banner -->
    <div id="section6">
        <div class="box">
            <div class="box-inner-margin banner-text basic-text-shadow">
                <h1 class="highlighted banner-title">Abriendo oportunidades educativas</h1>
                <h2 class="subtitle">a miles de jóvenes técnicos</h2>
            </div>
        </div>
    </div>
    <!-- Apoyo -->
    <div id="section7">
        <div class="box">
            <div class="box-inner-margin">
                <h1 class="title">
                    Apoyo integral a la educación media
                    <span class="primary">técnico-profesional</span>
                </h1>
                <div class="line"></div>
                <div class="description-container">
                    <h3 class="description">
                        Asistencia orientada al desarrollo de aptitudes, competencias, habilidades y conocimientos
                    </h3>
                </div>
                <div class="support-items-container">
                    <div class="support-item">
                        <img
                            src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/abily/landing/icono_multimedia.png"
                            alt="material-multimedia"
                            class="support-imagen primary-item"
                        />
                        <h3 class="title">Material Multimedia</h3>
                        <h4 class="support-description">
                            Accede a nuestra creciente
                            <strong>biblioteca</strong>
                            de material multimedia. Encontrarás clases, videos y guías de estudio ordenadas por carrera.
                        </h4>
                    </div>
                    <div class="support-item primary-item">
                        <img
                            src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/abily/landing/icono_pruebas.png"
                            alt="pruebas-de-preparacion"
                            class="support-imagen white-icon"
                        />
                        <h3 class="title white-text">Pruebas de preparación</h3>
                        <h3 class="support-description white-text">
                            A través de
                            <strong>pruebas de preparación por carreras</strong>
                            , te recomendamos planes y sesiones de estudio personalizadas para mejorar tus conocimientos
                            y habilidades.
                        </h3>
                    </div>
                    <div class="support-item">
                        <img
                            src="https://bucket-puntaje-reloaded.s3.us-west-1.amazonaws.com/abily/landing/icono_feedback.png"
                            alt="feedback-inmediato"
                            class="support-imagen primary-item"
                        />

                        <h3 class="title">Feedback Inmediato</h3>
                        <h4 class="support-description">
                            Entrena con nuestras
                            <strong>evaluaciones de práctica</strong>
                            y accede a múltiples reportes detallados sobre tu rendimiento en cada asignatura.
                        </h4>
                    </div>
                </div>
                <button class="btn btn-lg basic-shadow main-button" (click)="goToRegister()">
                    Explora todas las funcionalidades
                </button>
            </div>
        </div>
    </div>
    <!-- Registrarse -->
    <div id="section8">
        <div class="box">
            <div class="basic-text-shadow">
                <h1 class="highlighted banner-title">¿Aún no eres miembro?</h1>
                <h2 class="subtitle">
                    Súmate a la única plataforma de preparación para la enseñanza Media
                    <strong>técnico-profesional</strong>
                </h2>
            </div>
            <button class="btn btn-lg basic-shadow main-button" (click)="goToRegister()">¡Regístrate gratis!</button>
        </div>
    </div>
    <!-- Footer -->
    <footer>
        <span>
            <img
                class="footer-logo"
                src="{{ logoFooter }}"
                alt="{{ config.plataforma.info.companyName }}"
                title="{{ config.plataforma.info.companyName }}"
            />
            <ng-container *ngFor="let logo of logosExtra">
                <a href="{{ logo.link }}">
                    <img class="footer-logo" src="{{ logo.src }}" alt="{{ logo.title }}" title="{{ logo.title }}" />
                </a>
            </ng-container>
        </span>

        <div class="footer-col">
            <h3 class="contact">Contáctanos</h3>
            <ul>
                <li>
                    <p>
                        <fa name="envelope-o"></fa>
                        &nbsp;{{ config.plataforma.info.contacto.mail }}
                    </p>
                </li>
            </ul>
        </div>
    </footer>
</div>
